import React from 'react'

export interface FloatIconProps {
  onClick: () => void
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  label?: string
}

// TODO: Figure out a more accessible way to do this. Buttons are better.
const FauxButton: React.FC<FloatIconProps> = ({
  onClick,
  children,
  className,
  style,
  label
}) => (
  <div
    aria-label={label}
    tabIndex={0}
    className={className}
    style={{
      ...DEFAULT_STYLE,
      ...style
    }}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    }}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget.click()
      }
    }}
  >
    {children}
  </div>
)

export default FauxButton

const DEFAULT_STYLE: React.CSSProperties = {
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '1.5em',
  width: '1.5em',
  padding: '0.25em',
  borderRadius: '50%'
}
