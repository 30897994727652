import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Lightbulb, LightbulbFill } from 'react-bootstrap-icons'

const DarkToggleButton: React.FC = () => {
  const [dark, setDark] = useState<boolean>(() => loadDarkModePref())

  // When dark changes, update the theme attribute on the <html> element
  useEffect(() => {
    document.documentElement.setAttribute(
      'data-bs-theme',
      dark ? 'dark' : 'light'
    )
  }, [dark])

  // Listen for this value changing in localStorage on other tabs
  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.isTrusted && e.key === 'dark' && e.newValue !== null) {
        setDark(e.newValue === 'true')
      }
    }
    window.addEventListener('storage', listener)
    return () => window.removeEventListener('storage', listener)
  })

  return (
    <Button
      onClick={() => {
        saveDarkModePref(!dark)
        setDark(!dark)
      }}
      variant="clean"
    >
      {dark ? <LightbulbFill /> : <Lightbulb />}
    </Button>
  )
}

export default DarkToggleButton

/**
 * Loads the user's dark mode preference from localStorage. If no preference
 * exists, queries the user's OS for their preferred color scheme and saves it
 * to localStorage. If the browser doesn't support media queries, assumes light.
 * @returns true if the user prefers dark mode, false otherwise.
 */
const loadDarkModePref = (): boolean => {
  const local = localStorage.getItem('dark')
  if (local === null) return defaultDarkModePref()
  return local === 'true'
}

/**
 * Saves the user's dark mode preference to localStorage.
 * @param dark true if the user prefers dark mode, false otherwise.
 */
const saveDarkModePref = (dark: boolean) => {
  localStorage.setItem('dark', dark ? 'true' : 'false')
}

/**
 * Queries the user's OS for their preferred color scheme and saves it to
 * localStorage. If the browser doesn't support media queries, assumes light.
 * @returns true if the user's OS is set to dark mode, false otherwise.
 */
const defaultDarkModePref = () => {
  if (!window.matchMedia) return false
  const dark = window.matchMedia('(prefers-color-scheme: dark)').matches
  saveDarkModePref(dark)
  return dark
}
