import React from 'react'
import { Timer } from './app'
import TimerListItem from './timer-list-item'
import { ListGroup } from 'react-bootstrap'

export interface TimerListProps {
  timers: Timer[]
  now: number
  max: number
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}

const TimerList: React.FC<TimerListProps> = ({
  timers,
  now,
  max,
  onEdit,
  onDelete
}) => {
  return (
    <ListGroup>
      {timers.map((t) => (
        <TimerListItem
          key={t.id}
          timer={t}
          now={now}
          max={max}
          onEdit={() => onEdit(t.id)}
          onDelete={() => onDelete(t.id)}
        />
      ))}
    </ListGroup>
  )
}

export default TimerList
