import React, { useEffect, useMemo, useState } from 'react'
import { useInterval } from '../helpers/react-helpers'
import TimerList from './timer-list'
import TimerModal from './timer-modal'
import { v4 as uuid } from 'uuid'
import { Button, ButtonToolbar, Container, Navbar } from 'react-bootstrap'
import { Optional } from '../helpers/type-helpers'
import DarkToggleButton from './dark-toggle-button'

export type Timer = {
  id: string
  title: string
  date: string
}

const App: React.FC = () => {
  const [now, setNow] = useState(new Date().getTime())
  useInterval(() => setNow(new Date().getTime()), 1000)

  const [timers, setTimers] = useState<Timer[]>(() => {
    const timers = localStorage.getItem('timers')
    if (!timers) return []
    return JSON.parse(timers)
  })

  const setTimersWithLocalStorage = (timers: Timer[]) => {
    localStorage.setItem('timers', JSON.stringify(timers))
    setTimers(timers)
  }

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.isTrusted && e.key === 'timers' && e.newValue !== null) {
        setTimers(JSON.parse(e.newValue))
      }
    }
    window.addEventListener('storage', listener)
    return () => window.removeEventListener('storage', listener)
  })

  const addTimer = (timer: Omit<Timer, 'id'>) => {
    const newTimers = [...timers, { ...timer, id: uuid() }]
    setTimersWithLocalStorage(sortTimers(newTimers))
  }

  const updateTimer = (timer: Timer) => {
    const index = timers.findIndex((t) => t.id === timer.id)
    const newTimers = timers.slice()
    newTimers[index] = timer
    setTimersWithLocalStorage(sortTimers(newTimers))
  }

  const deleteTimer = (id: string) => {
    setTimersWithLocalStorage(timers.filter((t) => t.id !== id))
  }

  const max = useMemo(
    () =>
      timers.length ? new Date(timers[timers.length - 1].date).getTime() : 0,
    [timers]
  )

  const [modalData, setModalData] = useState<Optional<Timer, 'id'>>()

  const onAdd = () => {
    setModalData({ title: '', date: '' })
  }

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>
            <img
              src={process.env.PUBLIC_URL + '/logo192.png'}
              className="me-1"
              height="24"
              style={{ verticalAlign: 'text-bottom', height: 24 }}
              alt="hapst icon"
            />
            hapst
          </Navbar.Brand>
          <ButtonToolbar className="float-end">
            <Button className="me-2" onClick={onAdd}>
              Add
            </Button>

            <DarkToggleButton />
          </ButtonToolbar>
        </Container>
      </Navbar>

      <Container className="mt-4">
        <TimerList
          timers={timers}
          now={now}
          max={max}
          onEdit={(id) => setModalData(timers.find((t) => t.id === id))}
          onDelete={(id) => deleteTimer(id)}
        />
      </Container>

      {modalData && (
        <TimerModal
          timer={modalData}
          onSave={(timer) => {
            if (timer.id) {
              updateTimer(timer as Timer)
            } else {
              addTimer(timer)
            }
            setModalData(undefined)
          }}
          onCancel={() => setModalData(undefined)}
          onDelete={() => {
            if (modalData.id) {
              deleteTimer(modalData.id)
            }
            setModalData(undefined)
          }}
        />
      )}
    </>
  )
}

export default App

const sortTimers = (timers: Timer[]): Timer[] =>
  timers.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
