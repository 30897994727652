import { useEffect } from 'react'

/**
 * Custom hook for using setInterval in React
 * @param fn The function to be called on an interval
 * @param ms The time in milliseconds between calls
 */
export const useInterval = (fn: () => void, ms: number) => {
  useEffect(() => {
    const timer = setInterval(fn, ms)
    return () => clearInterval(timer)
  })
}
