import React, { useMemo } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { TimeParts } from './timer-list-item'
import classNames from 'classnames'

export interface TimeBarProps {
  parts: TimeParts
  ms: number
}

const TimeBar: React.FC<TimeBarProps> = ({ parts }) => {
  const [years, days, hours, minutes, seconds] = parts
  const yearsDone = years === 0
  const daysDone = yearsDone && days === 0
  const hoursDone = daysDone && hours === 0
  const minutesDone = hoursDone && minutes === 0
  const secondsDone = minutesDone && seconds === 0

  // TODO: figure out how to optimize this a bit
  const [barMax, barNow, barRem, fullBars, nowVar, remVar] = secondsDone
    ? [1, 0, 0, 0, undefined, undefined] // Done
    : minutesDone
    ? [60, seconds, 0, 0, 'primary', undefined] // Final minute's seconds
    : hoursDone
    ? [60, seconds, 0, minutes, 'primary', undefined] // Seconds
    : daysDone
    ? [3600, minutes * 60, seconds, hours, 'success', 'primary'] // Minutes
    : yearsDone
    ? [86400, hours * 3600, minutes * 60 + seconds, days, 'warning', 'success'] // Hours
    : [
        31536000,
        days * 86400,
        hours * 3600 + minutes * 60 + seconds,
        years,
        'danger',
        'warning'
      ] // Days

  return (
    <>
      <ProgressBar min={0} max={barMax} className="mt-1">
        <ProgressBar
          min={0}
          max={barMax}
          variant={nowVar}
          now={barNow}
          animated={minutesDone}
        />
        <ProgressBar min={0} max={barMax} variant={remVar} now={barRem} />
      </ProgressBar>
      {fullBars > 0 && <Dots count={fullBars} variant={nowVar} />}
    </>
  )
}

export default TimeBar

//
// Dots
//

const Dots: React.FC<{ count: number; variant?: string }> = ({
  count,
  variant
}) => {
  const dots = useMemo(() => Array.from(Array(count), (_, i) => i + 1), [count])

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {dots.map((i) => (
        <div
          key={i}
          style={DOT_STYLE}
          className={classNames([variant && `bg-${variant}`, 'me-1 mt-1'])}
        />
      ))}
    </div>
  )
}

const DOT_STYLE = {
  height: 8,
  width: 8,
  borderRadius: '50%'
}
