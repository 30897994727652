import React, { useMemo } from 'react'
import { Timer } from './app'
import { Col, ListGroupItem, Row } from 'react-bootstrap'
import { pluralize } from '../helpers/string-helpers'
import { Trash } from 'react-bootstrap-icons'
import FauxButton from './faux-button'
import TimeBar from './time-bar'

export interface TimerListItemProps {
  timer: Timer
  now: number
  max: number
  onEdit: () => void
  onDelete: () => void
}

export type TimeParts = readonly [number, number, number, number, number]

const TimerListItem: React.FC<TimerListItemProps> = ({
  timer,
  now,
  // max,
  onEdit,
  onDelete
}) => {
  const [dateTime, then] = useMemo(() => {
    const date = new Date(timer.date)
    const isMidnight =
      date.getHours() === 0 &&
      date.getMinutes() === 0 &&
      date.getSeconds() === 0
    const timeString = isMidnight
      ? 'Midnight'
      : date.toLocaleTimeString([], TIME_STRING_FORMAT)
    const dateString = date.toLocaleDateString()
    return [`${dateString} @ ${timeString}`, date.getTime()] as const
  }, [timer.date])

  const done = then <= now

  const parts = msToParts(then - now)

  const [first, rest] = useMemo(() => {
    if (done) return []
    const [first, ...rest] = partsToStrings(parts)
    return [first, rest.slice(0, 2).join(', ')]
  }, [parts])

  return (
    <ListGroupItem
      variant={done ? 'secondary' : undefined}
      action
      onClick={() => onEdit()}
      title={dateTime}
    >
      {done && (
        <FauxButton
          onClick={onDelete}
          style={{ position: 'absolute', top: '1em', right: '1em' }}
          label="Delete"
        >
          <Trash />
        </FauxButton>
      )}

      <Row>
        <Col>
          <p className="display-6 mb-0">{timer.title}</p>
          <p className="mb-0">{dateTime}</p>
        </Col>
        <Col>
          <p className="display-6 mb-0">{first ?? 'Done!'}</p>
          {rest !== undefined && <p className="mb-0">{rest}</p>}
        </Col>
      </Row>

      <TimeBar parts={parts} ms={then - now} />
    </ListGroupItem>
  )
}

export default TimerListItem

const msToParts = (ms: number): TimeParts =>
  ms > 0
    ? ([
        Math.floor(ms / 31536000000), // Years
        Math.floor(ms / 86400000) % 365, // Days
        Math.floor(ms / 3600000) % 24, // Hours
        Math.floor(ms / 60000) % 60, // Minutes
        Math.floor(ms / 1000) % 60 // Seconds
      ] as const)
    : ([0, 0, 0, 0, 0] as const)

const partsToStrings = ([y, d, h, m, s]: TimeParts) =>
  [
    y ? pluralize(y, 'Year') : '',
    y || d ? pluralize(d, 'Day') : '',
    y || d || h ? pluralize(h, 'Hour') : '',
    y || d || h || m ? pluralize(m, 'Minute') : '',
    y || d || h || m || s ? pluralize(s, 'Second') : ''
  ].filter(Boolean)

const TIME_STRING_FORMAT = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true
} as const
