import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Timer } from './app'
import { Optional } from '../helpers/type-helpers'
import { Col, Form, Row } from 'react-bootstrap'

export interface TimerModalProps {
  timer: Partial<Timer>
  onSave: (timer: Optional<Timer, 'id'>) => void
  onCancel: () => void
  onDelete: () => void
}

const TimerModal: React.FC<TimerModalProps> = ({
  timer,
  onSave,
  onCancel,
  onDelete
}) => {
  const [title, setTitle] = React.useState(() => timer.title || '')

  const [date, setDate] = React.useState(() => {
    if (!timer.date) return ''
    const date = new Date(timer.date)
    const y = date.getFullYear().toString()
    const m = (date.getMonth() + 1).toString().padStart(2, '0')
    const d = date.getDate().toString().padStart(2, '0')
    return [y, m, d].join('-')
  })

  const [time, setTime] = React.useState(() => {
    if (!timer.date) return ''
    const date = new Date(timer.date)
    const h = date.getHours().toString().padStart(2, '0')
    const m = date.getMinutes().toString().padStart(2, '0')
    const s = date.getSeconds().toString().padStart(2, '0')
    return `${h}:${m}:${s}`
  })

  return (
    <Modal show onHide={onCancel}>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          onSave({
            ...timer,
            title,
            date: new Date(`${date}T${time || '00:00:00'}`).toISOString()
          })
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{timer.id ? 'Edit' : 'Add'} Countdown</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Title*</Form.Label>
            <Form.Control
              required
              type="text"
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Col>
                <Form.Label>Date*</Form.Label>
                <Form.Control
                  required
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.currentTarget.value)}
                />
              </Col>
              <Col>
                <Form.Label>Time</Form.Label>
                <Form.Control
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.currentTarget.value)}
                />
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          {!!timer.id && (
            <Button
              variant="outline-danger"
              className="me-auto"
              onClick={() => onDelete()}
            >
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            {timer.id ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default TimerModal
